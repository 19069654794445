import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const CustomizedHairWigforMen = () => {

  const seoData = {
    title: 'Customized Wigs For Men in Delhi - Radiance Hair Studio',
    description: 'Get the finest customized hair wigs for men in Delhi, custom-designed by certified hair experts to match your preferences. Visit us today.',
    keywords: ['Customized Wig For Men, Customized Wigs For Men in Delhi, Customized Wig For Men Price, Customized Wigs For Men Near Me']
  };


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Customized-Hair-Wig-for-Men'sv.jpg",
      "https://www.radiancehairstudio.com/customized-wigs-for-men2.png",
      "https://www.radiancehairstudio.com/customized-wigs-for-men1.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/customized-wigs-for-men.php",
    "category": "https://www.radiancehairstudio.com/customized-wigs-for-men.php",
    "description": "Get the finest customized hair wigs for men in Delhi, custom-designed by certified hair experts to match your preferences. Visit us today.",
    "disambiguatingDescription": [
      "Customized Wig For Men",
      "Customized Wigs For Men in Delhi",
      "Customized Wig For Men Price",
      "Customized Wigs For Men Near Me"
    ],
    "mainEntityOfPage": "https://www.radiancehairstudio.com/customized-wigs-for-men.php",
    "serviceType": "Customized Hair Wig for Men's"
  };

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Customized-Hair-Wig-for-Men'sv.jpg",
      content: (
        <div>
          <h2>Customized Hair Wig for Men's</h2>
          <p>
          Are you in need to stop showing your bald head? You might be suggested by many to go for different therapies, but to be very accurate, you need none. Choose a hair wig for men specifically customized for you. Your baldness will remain hidden and at the same time, you will develop a look that is absolutely impeccable.
          </p>
  
          <h2>Magic At Radiance</h2>
          <p>
          At Radiance, we provide all males a perfectly knitted wig with awesome measurement and quality. We provide you with the perfect material design only – human hair and freestyle.
          </p>
          <p>While looking to avail the <a href="/wigs-for-men.php" style={{ color: "#373737"}}>best hair wig</a> for a man in Delhi, you will have to select the right kind of hair parts too. Among the different parts, choose among – baby hair, front part or back part and finally the one that surrounds the entire perimeter. Here at Radiance, we will provide you with that particular wig you actually need, exclusively designed with 100% Natural Human Hair. We offer our customers many kinds of Non-Surgical Hair Replacement procedures with natural human <a href="/hair-extensions-for-men.php" style={{ color: "#373737"}}>hair extensions.</a></p>
          <p>At our <a href="/" style={{ color: "#373737"}}>Hair Studio</a>, you will be able to customize your hair extensions as per your own preference and that too at the best rates. Our certified hair experts will guide you in choosing a proper wig along with the perfect style and color that suits you. But one thing you must go through, before getting a wig for yourself – the benefits this wigs will give you, leaving all other hair treatment plans. Here is a detail of the same below –</p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/customized-wigs-for-men1.png",
      largeImg: "/customized-wigs-for-men2.png",
      content: (
        <div>
          <h2>Benefits of using customized hair wig for men</h2>
         
          <ul  >													
                <li>These wigs are designed to fit your bald head in the best way. They will fit your skull and will define a new look for you. The specialty of this new look is its awesome natural style. By staring at you, none will even identify you to wear a wig. </li>
                <li>The material used in this wigs is too good. Since made of natural hair, the outer skirt of the wig displays you like a normally haired man. From inside, the wigs are even greater. You can keep those on your skull for day-long. </li>
                <li>You can remain flexible with the use of this hair wig for man in Delhi. They can be worn like a cap. So, put it on your head, as you move outside and get off from it, while inside. </li>
                <li>Pricing of these wigs is also quite less when compared to other hair elongating mechanisms. Hence, you can well fit your budget with the wig too.  </li> 
          </ul>
          
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs of customized wigs",
      description:
        "Here are some questions you might have faced while thinking to go for a hair wig. We tried to make your confusion clear by answering them at below.",
      description2: "",
      faq: [
        {
          title: "How easy is to wear the wigs?",
          description:
            "At Radiance, we will be providing you the hair wig for man in Delhi that you can easily use like a cap. Wear it or open it as you wish.",
        },
        {
          title: "How much extra will I have to pay for the extra long hair?",
          description:
            "Usually, the pre-defined wig comes with 8 to 24 inches length and man does fit this hair length perfectly. So for males, there is no need to pay anything extra.",
        },
        {
          title: "Will one wig cover all the head?",
          description:
            "The hair wig for men we provide at Radiance is of four kinds – for front part and back part, baby hair and full perimeter hair. You can choose the right one that will cover your baldness.",
        },
        {
          title: "How natural the look will be? ",
          description:
            "The look that you will generate is absolutely a natural one. None will be able to differentiate your natural hair and wig on your scalp, even when they give a closer look.",
        },
      ],
    },
    
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Customized Hair Wig for Men's" banner="/customized-hair-wig-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Customized Hair Wig for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default CustomizedHairWigforMen;
